
import Vue from 'vue';
import { ref } from "vue";
import HelloWorld from './components/HelloWorld.vue';

interface Line {
  text: string;
  copied: boolean;
}

export default Vue.extend({
  name: 'App',
  components: {
    HelloWorld
  },
  setup(_prop) {
    const clipboardAvailable = ref(navigator.clipboard !== undefined);
    const input = ref("");
    const lines = ref([] as Line[]);
    const onClickCopy = function(line: Line) {
      navigator.clipboard.writeText(line.text);
      line.copied = true;
    };
    const onClickStart = function() {
      lines.value = input.value.replace(/^\s+/, "").replace(/\s+$/, "").split("\n").map((line, index) => {
        return {index: index, text: line, copied: false};
      });
    };
    return {
      clipboardAvailable,
      input,
      lines,
      onClickCopy,
      onClickStart,
    }
  }
});
